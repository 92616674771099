import React, { useRef, useState } from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Button, DatePicker, Form, Modal, Spin, Table } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import QrReader from "react-qr-reader";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import _ from "lodash";

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div
        className="text-gray-700"
        style={{
          padding: 10,
          display: this.props.display,
          flexDirection: "row",
          width: 1250,
          flexWrap: "wrap",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        <style type="text/css" media="print">
          {"\
          @page { size: landscape; }\
        "}
        </style>
        {this.props.selected.map((id, key) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop:
                (key % 12 === 0 || (key % 12 === 1 && key > 12)) && key !== 0
                  ? 130
                  : 10,
              // width: "50%",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${require("../../assets/new-pass.png")})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                height: 1150,
                width: 1500,
                marginLeft: 50,
                objectFit: "fill",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  height: 71,
                  width: 70,
                  right: 330,
                  top: 430,
                }}
              >
                <QRCode
                  value={id?.id}
                  size={320}
                  logoWidth={70}
                  logoHeight={70}
                  logoImage={require("../../assets/cebu-province-logo.jpg")}
                />
              </div>
              <p
                style={{
                  position: "relative",
                  left: 34,
                  top: 894,
                  fontSize: 23,
                }}
              >{`CPG-LSTP ${_.padStart(
                id?.control_no?.toString(),
                3,
                "00"
              )}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 384,
                  top: 854,
                  fontSize: 23,
                }}
              >{`${id?.company_name}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 36,
                  top: 989,
                  fontSize: 23,
                }}
              >{`${id?.plate_no?.toUpperCase()}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 373,
                  top: 950,
                  fontSize: 23,
                }}
              >{`${id?.region?.toUpperCase()}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 632,
                  top: 916,
                  fontSize: 23,
                }}
              >{`${id?.province?.toUpperCase()}`}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const ScanView = (props) => {
  const {
    handleError,
    handleScan,
    showScanner,
    setShowScanner,
    vehicleData,
    scanning,
    setVehicleData,
    setVisible,
    visible,
    handlePassApplication,
    form,
  } = useContext(PageContext);

  const [pdfVisible, setPdfVisible] = useState(false);

  const componentRef = useRef();

  const columns = [
    {
      title: "Date from",
      key: "date_from",
      render: (data) => <p>{moment(data?.date_from).format("MMM-DD-YYYY")}</p>,
    },
    {
      title: "Date to",
      key: "date_to",
      render: (data) => <p>{moment(data?.date_to).format("MMM-DD-YYYY")}</p>,
    },
    {
      title: "Status",
      key: "status",
      render: (data) => <p>{data?.status}</p>,
    },
  ];

  const renderDetails = () => {
    if (vehicleData !== "") {
      const data = JSON.parse(vehicleData);

    return (
      <div className="mb-5">
        <p className="font-bold">Company name: <span className="font-medium text-blue-500">{data?.vehicle_info?.company_name}</span></p>
        <p className="font-bold">Company address: <span className="font-medium text-blue-500">{data?.vehicle_info?.company_address}</span></p>
        <p className="font-bold">Drivers name: <span className="font-medium text-blue-500">{data?.vehicle_info?.drivers_name}</span></p>
        <p className="font-bold mb-5">Status: <span className="font-medium text-green-400">{data?.vehicle_info?.status}</span></p>
        <p className="font-bold">Submitted pass applications</p>
        <Table columns={columns} dataSource={data?.pass_applications} />
        {data?.vehicle_info?.status === 'approved' && (
          <Button
            className="mb-5"
            type="primary"
            onClick={() => setVisible(true)}
          >
            Apply Travel Pass
          </Button>
        )}
        <br />
        <Button
          className="mb-5"
          className="bg-green-400 border-green-400"
          type="primary"
          onClick={() => setPdfVisible(true)}
        >
          Download/Print Pass
        </Button>
      </div>
    );
    }
  };

  return (
    <div className="flex flex-col h-screen items-center mb-56">
      <div className="flex flex-col items-center self-center w-2/5">
        <img
          src={require("../../assets/cebu-province-logo.jpg")}
          className="h-32 rounded-lg"
        />
        <div>
          <p className="text-center text-sm lg:text-2xl">
            VEHICLE PASS QR Code Scanner
          </p>
        </div>
      </div>
      <p className="text-base mb-10">To apply for a new vehicle pass <a href="/" className="text-blue-400 font-bold text-base underline">Go to Registration page</a></p>
      <p className="mb-5 font-medium">Scan your QR Code to proceed</p>
      <p className="mb-5">NOTE: For iOS/iPhone users please use Safari Brower in order for the scanner to work.</p>
      <div>
        {scanning && (
          <div>
            <Spin />
            <p>Checking QR Code...</p>
          </div>
        )}
        {showScanner && !scanning && (
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: 200, height: 200 }}
          />
        )}
        {vehicleData !== null && !scanning && renderDetails()}
      </div>
      {!showScanner && !scanning && (
        <Button
          type="primary"
          onClick={() => {
            setVehicleData(null);
            setShowScanner(true);
          }}
        >
          Show Scanner
        </Button>
      )}
      <div className="mt-56">
        <p>Copyright @Vehicle Pass 2021</p>
      </div>

      <Modal
        title={"Apply for pass"}
        visible={visible}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setVisible(false)}
      >
        <div>
          <p>Input Date Ranges</p>
          <Form form={form} onFinish={handlePassApplication}>
            <Form.Item
              name="date_range"
              rules={[{ required: true, message: "Must selecte date range" }]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Submit application
            </Button>
          </Form>
        </div>
      </Modal>

      <Modal
        title={"Download Pass PDF File"}
        visible={pdfVisible}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setPdfVisible(false)}
      >
        {vehicleData !== '' && (
          <div>
            <ReactToPrint
            trigger={() => (
              <button className="font-bold text-blue-500">
                Print/Download Pass (PDF)
              </button>
            )}
            content={() => componentRef.current}
          />
          <ComponentToPrint
            ref={componentRef}
            selected={[JSON.parse(vehicleData)?.vehicle_info]}
            display={"flex"}
          />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ScanView;
