import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import RegistrationView from "./view";
import { PageContext } from "../../lib/context";
import axios from "axios";

const Registration = (props) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [response, setResponse] = useState(null);
  const [analyzeData, setAnalyzeData] = useState(null);

  const handleImageUpload = (key, file) => {
    setImages([...images, { key, file }]);
  };

  // const updateResponse = useCallback((data)=>{
  //   setResponse(JSON.parse(data));
  // },[response,setResponse])

  const handleRequest = () => {
    setResponse(null);
    setLoading(true);
    console.log(images);
    if (images.length < 2) {
      message.error("Must upload both selfie and ID");
      setLoading(false);
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var formdata = new FormData();
    formdata.append("id", images.filter((i) => i.key === "id")[0].file);
    formdata.append("selfie", images.filter((i) => i.key === "selfie")[0].file);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("http://128.199.142.233:3040/upload", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        // updateResponse(result)
        setResponse(JSON.parse(result));
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const handleAnalyze = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "http://128.199.142.233:3040/analyze?id=" + response?.id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const parsed = JSON.parse(result);
        setAnalyzeData({ ...parsed });
        console.log({ ...parsed });
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  useMemo(()=>{
    if(response !== null) {
      handleAnalyze()
    }
  },[response])

  const handleReset = () => {
    setResponse(null)
    setAnalyzeData(null)
    setImages([])
  }

  const pageValues = {
    loading,
    handleImageUpload,
    response,
    handleRequest,
    analyzeData,
    handleAnalyze,
    images,
    handleReset
  };

  return (
    <PageContext.Provider value={pageValues}>
      <RegistrationView />
    </PageContext.Provider>
  );
};

export default Registration;
