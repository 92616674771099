import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Button, Spin, Upload } from "antd";
import {
  FileSearchOutlined,
  FileImageOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ReactJson from "react-json-view";

const RegistrationView = (props) => {
  const {
    loading,
    handleImageUpload,
    response,
    handleRequest,
    analyzeData,
    handleAnalyze,
    images,
    handleReset,
  } = useContext(PageContext);

  const renderAtrributes = () => {
    return Object.keys(analyzeData?.attributes ?? {}).map((attr) => {
      return (
        <p className="font-bold">
          {attr.toUpperCase()}:
          <span className="text-blue-400 font-medium"> {analyzeData?.attributes?.[attr]}</span>
        </p>
      );
    });
  };

  return (
    <div className="flex flex-col h-auto items-center mb-56 justify-center items-center">
      <div
        className="border border-gray-200 rounded-lg shadow-lg w-3/5"
        style={{ height: "auto" }}
      >
        <div className="rounded-t-lg flex flex-col justify-center px-10 w-full bg-blue-400 h-20">
          <div className="self-start flex items-center flex-row">
            <FileImageOutlined className="text-white mr-2 text-lg" size={25} />
            <p className="text-white font-bold text-xl self-start">
              Image Recognition
            </p>
          </div>
          <p className="text-white text-sm self-start">
            Upload selfie and a valid ID
          </p>
        </div>
        <div className="m-5">
          {!analyzeData ? (
            <div>
              <Upload
                className="self-start mb-5"
                beforeUpload={(file) => handleImageUpload("id", file)}
                accept=".png,.jpg,.jpeg"
              >
                <Button icon={<UploadOutlined />}>Select ID</Button>
              </Upload>
              <div className="mt-5">
                <Upload
                  className="self-start mb-5 mt-5"
                  beforeUpload={(file) => handleImageUpload("selfie", file)}
                  accept=".png,.jpg,.jpeg"
                >
                  <Button icon={<UploadOutlined />}>Select Selfie</Button>
                </Upload>
              </div>
              <div className="mt-3 mb-5">
                <Button
                  type="primary"
                  onClick={handleRequest}
                  className={"text-white"}
                >
                  {loading ? (
                    <>
                      <Spin /> Uploading and Analyzing
                    </>
                  ) : (
                    "Upload"
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <Button type="primary" onClick={handleReset} className="mb-5">
              Upload new images
            </Button>
          )}
          <div className="flex flex-row justify-evenly">
            {images.length > 0 && (
              <img
                src={URL.createObjectURL(images?.[0]?.file)}
                style={{ height: 250, width: 400, objectFit: "contain" }}
              />
            )}
            {images.length > 1 && (
              <img
                src={URL.createObjectURL(images?.[1]?.file)}
                style={{ height: 250, width: 400, objectFit: "contain" }}
              />
            )}
          </div>
          {analyzeData && (
            <div className="flex flex-col items-start mt-10">
              <p className="font-bold">
                ID type:{" "}
                <span className="font-medium text-blue-400">
                  {analyzeData?.type}
                </span>
              </p>
              {renderAtrributes()}
              <p className="font-bold">
                Similarity:{" "}
                <span className="font-medium text-blue-400">
                  {analyzeData?.faceMatches?.[0]?.similarity}
                </span>
              </p>
            </div>
          )}
          <div className="mt-5">
            {response !== null && (
              <pre style={{ whiteSpace: "break-spaces" }}>
                {JSON.stringify(response)}
              </pre>
            )}
          </div>
          <div className="mt-5">
            {analyzeData !== null && (
              <pre style={{ whiteSpace: "break-spaces" }}>
                {JSON.stringify(analyzeData)}
              </pre>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationView;
